import React, { useState } from "react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    workEmail: "",
    phone: "",
    employeeType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Here you would typically send the form data to your backend
  };

  return (
    <div className="p-5 flex flex-col justify-center items-center gap-5 ">
      <div className="flex flex-row md:flex-row gap-5">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center xl:w-1/2 h-[calc(100vh-8.25rem)] rounded-[1.5rem] mx-auto px-16">
          <div className=" mx-auto 2xl:p-10 rounded-lg shadow-sm">
            <h1 className="text-3xl font-black mb-2">Chat with our team</h1>
            <p className="mb-6 font-bold">
              Need help with something? Get in touch with our team and we'll get
              in touch.
            </p>

            <form onSubmit={handleSubmit} className="space-y-4 text-[#254336] ">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First name"
                    className="w-full px-5 py-3 border-b border-gray-300 focus:border-blue-500 rounded-3xl focus:outline-none"
                    required
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last name"
                    className="w-full px-5 py-3 border-b border-gray-300 focus:border-blue-500 rounded-3xl  focus:outline-none"
                    required
                  />
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  placeholder="Company"
                  className="w-full px-5 py-3 border-b border-gray-300 focus:border-blue-500 rounded-3xl  focus:outline-none"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="workEmail"
                  value={formData.workEmail}
                  onChange={handleChange}
                  placeholder="Work email"
                  className="w-full px-5 py-3 border-b border-gray-300 focus:border-blue-500 rounded-3xl  focus:outline-none"
                  required
                />
              </div>
              <div className="flex items-center border-gray-300">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone number"
                  className="flex-grow px-5 py-3 rounded-3xl  focus:outline-none"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-[#FEF3E2] text-[#254336] font-black rounded-3xl focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Get in touch
              </button>
            </form>
          </div>
        </div>
        <div className="hidden lg:block md:w-1/2 ">
          <img
            src="https://images.unsplash.com/photo-1433086966358-54859d0ed716?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Sustainable energy project"
            className="w-full h-[calc(100vh-8.25rem)] rounded-3xl object-cover  shadow-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
