import React from "react";
import { AiOutlineStock } from "react-icons/ai";
import { PiFarm } from "react-icons/pi";
import {
  FaChartLine,
  FaIndustry,
  FaGlobe,
  FaSolarPanel,
  FaTicketAlt,
  FaBuilding,
  FaHandshake,
  FaShieldAlt,
} from "react-icons/fa";

const EnergyType = ({ title, icon, description, isActive }) => (
  <div
    className={`p-6 border-[#254336] border-8 rounded-2xl ${
      isActive ? "bg-[#FEF3E2] text-[#254336]" : "bg-white"
    }`}
  >
    <div className="flex flex-row items-center">
      <div className="xl:text-lg 2xl:text-2xl mb-2">{icon}</div>
      <h3 className="xl:text-lg 2xl:text-xl  font-black mb-2 ml-3">{title}</h3>
    </div>
    {description && (
      <p className="mb-4 text-sm 2xl:text-lg text-bold">{description}</p>
    )}
    {!isActive && (
      <button className="text-blue-600 hover:underline">VIEW DETAILS</button>
    )}
  </div>
);

const InvestmentStrategy = () => {
  return (
    <div className="p-5 flex flex-col justify-center items-center md:flex-col gap-5 ">
      <div className=" flex flex-col  rounded-[1.5rem]">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center items-center h-auto w-full mb-5 rounded-[1.5rem] mx-auto  py-10 px-10 lg:py-32 lg:px-72">
          <AiOutlineStock className="text-6xl mb-5" />
          <h1 className="xl:text-4xl 2xl:text-6xl font-black mb-4">
            Our investment strategy
          </h1>
        </div>
        <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <EnergyType
            title="Equity ticket sizes"
            icon={<FaTicketAlt />}
            description="Per investment flexible but typically range from $5m to $20m."
            isActive={true}
          />

          <EnergyType
            title="Essential energy transition infrastructure"
            icon={<FaBuilding />}
            description="20+ year, inflation linked, USD contracted cash flows."
            isActive={true}
          />

          <EnergyType
            title="Core infra plus returns"
            icon={<FaShieldAlt />}
            description="Stable, insured cashflows; business cycle agnostic returns; investments insulated from market, interest-rate, commodity & currency volatility."
            isActive={true}
          />

          <EnergyType
            title="No fees, No exits"
            icon={<FaHandshake />}
            description="We invest alongside our investors & EPC / developer partners aligning all stakeholders with long-term dividend cashflows in a permanent capital vehicle."
            isActive={true}
          />
        </div>
      </div>
      <div className=" flex flex-col rounded-[1.5rem]">
        <div className=" text-[#FEF3E2] bg-[#254336] flex flex-col justify-center items-center h-auto w-full mb-5 rounded-[1.5rem] mx-auto py-10 px-10 lg:py-32 lg:px-72">
          <PiFarm className="text-6xl mb-5" />
          <h1 className="xl:text-3xl 2xl:text-6xl font-black mb-4">
            Investment rationale: Why invest in renewable energy for industrial,
            mining, & agricultural companies in Africa?
          </h1>
        </div>

        <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <EnergyType
            title="Insatiable Demand"
            icon={<FaChartLine />}
            description="Commercial & industrial customers represent 75% of total energy demand in SSA. Africa's solar, wind, & hydro resources are significantly better than northern hemisphere resources."
            isActive={true}
          />

          <EnergyType
            title="50% of companies rely on back-up diesel generators"
            icon={<FaIndustry />}
            description="More than $20 billion annually is spent on-site on diesel to ensure business continuity due to unreliable electricity grids."
            isActive={true}
          />

          <EnergyType
            title="Global Corporate PPA volumes have increased 9x since 2016"
            icon={<FaGlobe />}
            description="Over 200 corporations signed PPAs with over 150 project developers in 30 countries in 2023 alone."
            isActive={true}
          />

          <EnergyType
            title="Inside-the-fence PPAs are becoming the norm in Africa"
            icon={<FaSolarPanel />}
            description="Since 2022, 65% of new capacity additions in Africa have come from corporates contracting directly with developers / IPPs."
            isActive={true}
          />
        </div>
      </div>
    </div>
  );
};

export default InvestmentStrategy;
