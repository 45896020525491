import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AnimatePresence } from "framer-motion";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import OurApproach from "./pages/OurApproach";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import TransitionWrapper from "./components/TransitionWrapper";
import OurTeam from "./pages/OurTeam";
import ContactUs from "./pages/Contact";
import InvestmentStrategy from "./pages/InvestmentStrategy";
const root = ReactDOM.createRoot(document.getElementById("root"));

const AnimatedRoutes = () => {
  const location = useLocation();
  const year = new Date().getFullYear();
  return (
    <div className="h-full bg-gray-200 ">
      <div className="w-full mx-auto rounded-3xl overflow-hidden relative">
        <Header />
        <AnimatePresence mode="wait">
          <div className="mt-[5.5rem] md:mt-0">
            <TransitionWrapper key={location.pathname}>
              <Routes location={location}>
                <Route path="/" element={<App />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/our-approach" element={<OurApproach />} />
                  <Route path="/team" element={<OurTeam />} />
                  <Route
                    path="/investment-strategy"
                    element={<InvestmentStrategy />}
                  />
                </Route>
              </Routes>
            </TransitionWrapper>
          </div>
        </AnimatePresence>
        <Footer />
        <h1 className="text-center text-xs m-5 font-semibold">
          © {year} Designed by{" "}
          <a
            className="font-black"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/ben-hazan-416213119"
          >
            Ben Hazan.
          </a>
        </h1>
      </div>
    </div>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  </React.StrictMode>
);
