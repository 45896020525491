import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="fixed top-0 left-0 md:relative right-0 z-50 shadow-2xl md:shadow-none">
      <header
        className={`bg-[#254336] mt-0 md:mt-5 mx-0 md:mx-5 transition-all duration-200 ${
          mobileMenuOpen ? "md:rounded-t-[1.5rem]" : "md:rounded-[1.5rem]"
        }`}
      >
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <Link
              to="/"
              className="-m-1.5 p-1.5"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="text-[#BEC6A0] underline underline-offset-4 decoration-4 font-black ml-2 text-lg xl:text-2xl 2xl:text-3xl">
                SIA
              </span>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-[#FEF3E2]"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Toggle menu</span>
              {mobileMenuOpen ? (
                <XMarkIcon className="h-7 w-7" aria-hidden="true" />
              ) : (
                <Bars3Icon className="h-7 w-7" aria-hidden="true" />
              )}
            </button>
          </div>
          <div className="hidden lg:flex lg:text-lg lg:font-black lg:gap-x-12">
            <Link to="/our-approach" className=" leading-6 text-[#FEF3E2]">
              Our Approach
            </Link>
            <Link
              to="/investment-strategy"
              className="leading-6 text-[#FEF3E2]"
            >
              Investment Strategy
            </Link>
            <Link to="/team" className="leading-6 text-[#FEF3E2]">
              Our Team
            </Link>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {/* <Link
              to="/contact"
              className="bg-[#FEF3E2] font-black px-4 py-2 rounded-full text-md transition duration-300 ease-in-out transform hover:scale-105 hover:bg-opacity-90 active:scale-100 text-[#254336]"
            >
              Contact Us
            </Link> */}
          </div>
        </nav>
      </header>
      <Transition
        show={mobileMenuOpen}
        enter="transition ease-out duration-900"
        enterFrom="opacity-0 -translate-y-5"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-900"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <div className="lg:hidden font-black absolute w-full top-full left-0 right-0 bg-[#254336] shadow-2xl overflow-hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/our-approach"
              className="block px-3 py-2 rounded-md text-base text-[#FEF3E2] hover:bg-[#1E3529]"
              onClick={() => setMobileMenuOpen(false)}
            >
              Our Approach
            </Link>
            <Link
              to="/investment-strategy"
              className="block px-3 py-2 rounded-md text-base text-[#FEF3E2] hover:bg-[#1E3529]"
              onClick={() => setMobileMenuOpen(false)}
            >
              Investment Strategy
            </Link>
            <Link
              to="/team"
              className="block px-3 py-2 rounded-md text-base text-[#FEF3E2] hover:bg-[#1E3529]"
              onClick={() => setMobileMenuOpen(false)}
            >
              Our Team
            </Link>
            {/* <Link
              to="/contact"
              className="block px-3 py-2 rounded-md text-base text-[#FEF3E2] hover:bg-[#1E3529]"
              onClick={() => setMobileMenuOpen(false)}
            >
              Contact Us
            </Link> */}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Header;
