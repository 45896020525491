import React from "react";
import { Link } from "react-router-dom";
import { FlagIcon } from "react-flag-kit";

const Footer = () => {
  const locations = [
    { country: "Global HQ", city: "London", flag: ["GB"] },
    { country: "Southern Africa", city: "Johannesburg", flag: ["ZA", "ZM"] },
    { country: "Eastern Africa", city: "Johannesburg", flag: ["UG"] },
    { country: "North Africa", city: "Casablanca", flag: ["MA"] },
  ];
  return (
    <footer className="bg-[#254336] text-[#FEF3E2]   rounded-[1.5rem] py-8 px-5 mx-5 mb-5">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-sm xl:text-md 2xl:text-lg">
          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-black mb-4">Quick Links</h3>
            <ul className="space-y-2 font-bold">
              <li>
                <Link to="/our-approach">Our Approach</Link>
              </li>
              <li>
                <Link to="/investment-strategy">Investment Strategy</Link>
              </li>
              <li>
                <Link to="/team">Our Team</Link>
              </li>
            </ul>
          </div>

          {/* Team Locations */}
          <div>
            <h3 className="text-xl font-bold mb-4">Team Locations</h3>
            <ul className="space-y-2">
              {locations.map((location, index) => (
                <li key={index} className="flex items-center font-bold">
                  {location.flag.map((flag) => (
                    <FlagIcon code={flag} size={17} className="mr-2" />
                  ))}
                  <span>{location.country}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Enquiries */}
          <div>
            <h3 className="text-xl font-black mb-4">Enquiries</h3>
            <ul className="space-y-2  font-bold">
              <li>info@sial.energy</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
